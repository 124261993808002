import React from 'react'
import {NavLink} from 'react-router-dom';
import PropTypes from "prop-types";

const NavBar = ({menu}) => {
    return (
        <nav className="site-main-menu">
            <ul>
                {menu.length > 0 ? 
                menu.map((item, index) => {
                    if(item.children && item.children.length > 0){
                        return(
                            <li className="has-children">
                                <NavLink to={process.env.PUBLIC_URL + item.navLink}><span className="menu-text">{item.title}</span></NavLink>
                                <span className="menu-toggle"><i className="far fa-angle-down"></i></span>
                                <ul className="sub-menu">
                                    {item.children.map((v, i) => {
                                        return (
                                            <li key={i}><NavLink to={process.env.PUBLIC_URL + v.navLink}><span className="menu-text">{v.title}</span></NavLink></li>
                                        )
                                    })}
                                </ul>
                            </li>
                        )
                    }else{
                        return(
                            <li key={index}>
                                <NavLink to={process.env.PUBLIC_URL + item.navLink}><span className="menu-text">{item.title}</span></NavLink>
                            </li>
                        )
                    }
                }): ""}
            </ul>
        </nav>
    )
}

NavBar.propTypes = {
    menu: PropTypes.array
};

export default NavBar
