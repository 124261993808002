const menu = [
    {
      title: 'Beranda',
      navLink: '/',
    },
    {
        title: 'Profil',
        navLink: '/profil',
        children: [
            {
                title: 'Visi Dan Misi',
                navLink: '/profil',
            },
            {
                title: 'Sambutan Kepala Sekolah',
                navLink: '/sambutan',
            },
            {
                title: 'Guru',
                navLink: '/guru',
            },
            {
                title: 'Karyawan',
                navLink: '/karyawan',
            }
        ]
    },
    {
        title: 'Program',
        navLink: '/program',
        children: [
            {
                title: 'Kurikulum',
                navLink: '/program',
            },
            {
                title: 'Kesiswaan',
                navLink: '/program',
            },
            {
                title: 'Ekstrakurikuler',
                navLink: '/program',
            },
            {
                title: 'Character Building',
                navLink: '/program',
            }
        ]
    },
    {
        title: 'Sekolah Penggerak',
        navLink: '/sekolah',
        children: [
            {
                title: 'Profil Pelajar Pancasila',
                navLink: '/program',
            },
            {
                title: 'Kegiatan ',
                navLink: '/program',
            }
        ]
    },
    {
        title: 'Fasilitas',
        navLink: '/fasilitas',
    },
    {
        title: 'Artikel',
        navLink: '/artikel',
        children: [
            {
                title: 'Berita',
                navLink: '/program',
            },
            {
                title: 'Prestasi',
                navLink: '/program',
            },
            {
                title: 'Kegiatan',
                navLink: '/program',
            }
        ]
    },
    {
        title: 'Galeri',
        navLink: '/galeri',
        children: [
            {
                title: 'Foto ',
                navLink: '/program',
            },
            {
                title: 'Video ',
                navLink: '/program',
            }
        ]
    },
    {
        title: 'Kontak',
        navLink: '/kontak',
    }
]
export default menu