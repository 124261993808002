import PropTypes from "prop-types";
import React from 'react';
import { BASE_API_GAMBAR } from "../../config";

const Breadcrumb = ({title, image}) => {
    return (
        <div className="page-title-section section section-padding-top overlay-two" style={{backgroundImage: `url(${BASE_API_GAMBAR}/${image})`}}>
            <div className="page-title">
                <div className="container">
                    <h1 className="title">{title}</h1>
                </div>
            </div>
        </div>
    )
}

Breadcrumb.propTypes = {
    title: PropTypes.string,
    image: PropTypes.string
};

export default Breadcrumb;
