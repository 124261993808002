import PropTypes from "prop-types";
import React from 'react';
import {Link} from "react-router-dom";
import { BASE_API_GAMBAR } from "../../config";

const BlogItem = ({ data }) => {
    return (
        <div className="blog">
            <div className="thumbnail">
                <Link to={process.env.PUBLIC_URL + `/blog-details/${data.beritaId}`} className="image"><img src={BASE_API_GAMBAR + "/" + data.gambar} alt="Blog Image" style={{objectFit: "cover", minWidth: "160%", maxWidth: "160%", maxHeight: "250px", minHeight: "250px"}} /></Link>
            </div>
            <div className="info">
                <ul className="meta">
                    <li><i className="far fa-calendar"></i>{data.createdAt}</li>
                    <li><i className="far fa-eye"></i>{data.view}</li>
                </ul>
                <h3 className="title"><Link to={process.env.PUBLIC_URL + `/blog-details/${data.beritaId}`}>{data.judul}</Link></h3>
                <Link to={process.env.PUBLIC_URL + `/blog-details/${data.beritaId}`} className="link"> <mark>Read More</mark> </Link>
            </div>
        </div>
    )
}

BlogItem.propTypes = {
    data: PropTypes.object
};

export default BlogItem
