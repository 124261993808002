import React, { useEffect, useState } from 'react';
import ReactLoading from "react-loading";
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import AboutFour from '../container/About/AboutFour';
// import Video from '../container/Video/Video';
// import AboutFive from '../container/About/AboutFive';
// import TestimonialContainer from '../container/Testimonial/TestimonialContainer';
// import CallToActionTwo from '../container/CallToAction/CallToActionTwo';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import {BASE_API} from '../config/index';

const AboutUs = () => {
    const [namaSekolah, setNamaSekolah] = useState("")
    const [dataSekolah, setDataSekolah] = useState({})
    const [visiMisi, setVisiMisi] = useState("")
    const [logo, setLogo] = useState("")
    const [loading, setLoading] = useState(true)
    const getData = () => {
        fetch(`${BASE_API}/about`)
        .then((response) => response.json())
        .then((data) => {
            setLoading(false)
            if(data.response.status){
                const resp = data.data
                setDataSekolah(resp.namaSekolah)
                setNamaSekolah(resp.namaSekolah.name)
                setLogo(resp.namaSekolah.logo)
                setVisiMisi(resp.visiMisi.descripsi)
            }
        }).catch((error) => {
            console.log(error)
            setLoading(false)
        })
    }

    useEffect(()=>{
        getData()
    }, [])
    return (
        <React.Fragment>
            {!loading ? <>
            <SEO title={namaSekolah} />
            <Header logo={logo} />
            <Breadcrumb 
                image="sampul/backgroud.jpg"
                title={namaSekolah}
            />
            <AboutFour visiMisi={visiMisi} logo={logo} namaSekolah={namaSekolah} />
            {/* <Video /> */}
            {/* <AboutFive /> */}
            {/* <TestimonialContainer classOption="bg-primary-blue" /> */}
            {/* <CallToActionTwo /> */}
            <Footer logo={logo} data={dataSekolah} />
            <ScrollToTop />
            </>:<><div style={{display: "flex", marginTop: "20%", justifyContent:"center", alignItems: "center"}}> 
                <ReactLoading type={"spin"} color="#000000" />
            </div><div style={{display: "flex", justifyContent:"center", alignItems: "center"}}> 
                Loading ...
            </div></> }
        </React.Fragment>
    )
}

export default AboutUs;



