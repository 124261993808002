import {useState, useEffect, useRef} from "react";
import SectionTitleTwo from '../../components/SectionTitles/SectionTitleTwo';
import Tilt from 'react-parallax-tilt';
import Parallax from 'parallax-js';
import PropTypes from "prop-types";
import { BASE_API_GAMBAR } from "../../config";

const AboutFour = ({logo, namaSekolah, visiMisi}) => {
    const [scale] = useState(1.04);
    const sceneEl = useRef(null);
    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
        relativeInput: true,
        })
        
        parallaxInstance.enable();

        return () => parallaxInstance.disable();

    }, [])
    return (
        <div className="section section-padding-top about-section-padding-bottom-200">
            <div className="container">
                <div className="row">

                    <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up">
                        <div className="about-content-area mt-0 mb-md-10 mb-10">
                            <SectionTitleTwo 
                                subTitle={namaSekolah}
                                // title="We think strategy, UX design, and web development"
                            />

                            <div className="row row-cols-sm-12 row-cols-auto mb-n6">
                                <div className="col mb-6">
                                    <div className="">
                                        <div className="desc" dangerouslySetInnerHTML={{__html: visiMisi}} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up" data-aos-delay="300">
                        <div className="about-image-area about-shape-animation right-0 mr-0">
                            <div className="about-image js-tilt">
                                <Tilt scale={scale} transitionSpeed={4000}>
                                    <img src={BASE_API_GAMBAR +"/"+ logo} alt="" width={"80%"} />
                                </Tilt>
                            </div>
                            <div className="shape shape-1" id="scene" ref={sceneEl}>
                                <span data-depth="1"><img src={process.env.PUBLIC_URL + "images/shape-animation/video-shape-1.png"} alt="" /></span>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

AboutFour.propTypes = {
    logo: PropTypes.string,
    namaSekolah: PropTypes.string,
    visiMisi: PropTypes.string
};

export default AboutFour;
