import PropTypes from "prop-types";
import React from 'react';
import { BASE_API_GAMBAR } from "../../config";

const SingleTeam = ({ data }) => {
    return (
        <div className="team p-3" style={{backgroundColor: "white", borderRadius: "10px", boxShadow: "10px black"}}>
            <div className="thumb">
                <img src={BASE_API_GAMBAR + "/" + data.image} alt="team Images"  style={{objectFit: "cover", minWidth: "100%", maxWidth: "100%", maxHeight: "100px", minHeight: "300px"}} />
                {/* <img src={process.env.PUBLIC_URL + data.image} alt="team Images" /> */}
                <div className="overlay"></div>
                <ul className="social-icon text-center">
                    <li className="facebook"><a target="_blank" rel="noopener noreferrer" href={data.urlFacebook} className="link"><i className="fab fa-facebook"></i></a></li>
                    <li className="twitter"><a target="_blank" rel="noopener noreferrer" href={data.urlTwitter} className="link" aria-label="Twitter"><i className="fab fa-twitter"></i></a></li>
                    <li className="linkedin"><a target="_blank" rel="noopener noreferrer" href={data.urlLinkedIn} className="link" aria-label="LinkedIn"><i className="fab fa-linkedin"></i></a></li>
                </ul>
            </div>
            <div className="team-info text-left">
                <div className="info" style={{borderTop: "1px solid", borderColor: "#d0d3d4", padding: "5px"}}>
                    <h5>{data.name}</h5>
                    <span>{data.designation}</span>
                </div>
            </div>
        </div>
    )
}

SingleTeam.propTypes = {
    data: PropTypes.object
};

export default SingleTeam;
