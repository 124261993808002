import React, { useEffect, useState } from 'react';
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import {BASE_API} from '../config/index';

const OnProgress = () => {
    const [namaSekolah, setNamaSekolah] = useState("")
    const [dataSekolah, setDataSekolah] = useState({})
    const [logo, setLogo] = useState("")
    const getData = () => {
        fetch(`${BASE_API}/about`)
        .then((response) => response.json())
        .then((data) => {
            if(data.response.status){
                const resp = data.data
                setDataSekolah(resp.namaSekolah)
                setNamaSekolah(resp.namaSekolah.name)
                setLogo(resp.namaSekolah.logo)
            }
        })
    }

    useEffect(()=>{
        getData()
    }, [])
    return (
        <React.Fragment>
            <SEO title={namaSekolah} />
            <Header logo={logo} />
            <Breadcrumb 
                image="sampul/backgroud.jpg"
                title={"On Progress"}
            />
            <Footer logo={logo} data={dataSekolah} />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default OnProgress;



