import React, { useEffect, useState } from 'react';
import ReactLoading from "react-loading";
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import Footer from '../container/Footer/Footer';
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import ScrollToTop from '../components/ScrollToTop.jsx';
import { BASE_API } from "../config"
import Team from '../container/Team/Team';
const Guru = () => {
    const [namaSekolah, setNamaSekolah] = useState("")
    const [dataSekolah, setDataSekolah] = useState({})
    const [logo, setLogo] = useState("")
    const [loading, setLoading] = useState(true)
    const [guru, setDataGuru] = useState([])

    const getData = () => {
        setLoading(true)
        fetch(`${BASE_API}/home`)
        .then((response) => response.json())
        .then((data) => {
            if(data.response.status){
                const resp = data.data
                setDataSekolah(resp.namaSekolah)
                setNamaSekolah(resp.namaSekolah.name)
                setLogo(resp.namaSekolah.logo)
                console.log(resp)
                const listGuru = []
                resp.guru.forEach(item => {
                    listGuru.push(
                        {
                            id: item.guruId,
                            image: item.image,
                            name: item.namaGuru,
                            designation : item.guruMapel
                        }
                    )
                })
                setDataGuru(listGuru)
            setLoading(false)
            }
        }).catch((err)=> {
            console.log(err)
            setLoading(false)
        })
    }

    useEffect(()=>{
        getData()
    }, [])

    return (
        <React.Fragment>
            {!loading ? <>
            <SEO title={namaSekolah} />
            <Header logo={logo} />
            <Breadcrumb 
                image="sampul/backgroud.jpg"
                title={"Guru Kami"}
            />
            <Team listGuru={guru}/>
            <Footer logo={logo} data={dataSekolah} />
            <ScrollToTop />
            </>:<><div style={{display: "flex", marginTop: "20%", justifyContent:"center", alignItems: "center"}}> 
                <ReactLoading type={"spin"} color="#000000" />
            </div><div style={{display: "flex", justifyContent:"center", alignItems: "center"}}> 
                Loading ...
            </div></> }
        </React.Fragment>
    )
}

export default Guru;



