import React, { useEffect, useState } from 'react';
import ReactLoading from "react-loading";
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import IntroSlider from '../container/IntroSlider/IntroSlider';
import HomeBlog from '../container/BlogGrid/HomeBlog';
import Newsletter from '../container/Newsletter/Newsletter';
import ContactInformation from '../container/ContactInformation/ContactInformation';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import { BASE_API } from "../config"
import contactData from "../data/contactInfo/contactInfo.json"
import Faq from '../container/Faq/Faq';
import Funfact from '../container/Funfact/Funfact';
import TestimonialContainer from '../container/Testimonial/TestimonialContainer';
const HomeOne = () => {
    const [namaSekolah, setNamaSekolah] = useState("")
    const [dataSekolah, setDataSekolah] = useState({})
    const [dataSlider, setDataSlider] = useState([])
    const [logo, setLogo] = useState("")
    const [contact, setContact] = useState([])
    const [sambutan, setSambutan] = useState([])
    const [jumlahData, setJumlahData] = useState([])
    const [testimoni, setTestioni] = useState([])
    const [dataBlog, setDataBlog] = useState([])
    const [loading, setLoading] = useState(true)
    const getData = () => {
        fetch(`${BASE_API}/home`)
        .then((response) => response.json())
        .then((data) => {
            setLoading(false)
            if(data.response.status){
                const resp = data.data
                setDataSekolah(resp.namaSekolah)
                setNamaSekolah(resp.namaSekolah.name)
                setLogo(resp.namaSekolah.logo)
                setSambutan(resp.sambutan.isiSambutan)
                setDataSlider(resp.slider)
                setJumlahData(resp.jumlah)
                setTestioni(resp.testimoni)
                setDataBlog(resp.beritaList)
                const list = []
                if(resp.slider.length > 0){
                    resp.slider.forEach(item => {
                        list.push({
                            id: item.id,
                            title: item.title,
                            desc: item.desc,
                            backgroundImage: item.backgroundImage
                        })
                    });
                }
                const contacts = contactData
                contacts[0].info = resp.namaSekolah.telp
                contacts[1].info = resp.namaSekolah.email
                setContact(contacts)
            }
        }).catch((err)=> {
            console.log(err)
            setLoading(false)
        })
    }

    useEffect(()=>{
        getData()
    }, [])

    return (
        <React.Fragment>
            {!loading ? <>
            <SEO title={namaSekolah} />
            <Header logo={logo} />
            <IntroSlider slider={dataSlider}/>
            <Faq logo={logo} sambutan={sambutan} namaSekolah={namaSekolah}/>
            <Funfact jumlahData={jumlahData} classOption={"mt-15"} />
            <TestimonialContainer testimoni={testimoni} classOption="bg-color-1" />
            <HomeBlog dataBlog={dataBlog} />
            <Newsletter />
            <ContactInformation data={contact} classOption="bg-color-1"/>
            <Footer logo={logo} data={dataSekolah} />
            <ScrollToTop />
            </>:<><div style={{display: "flex", marginTop: "20%", justifyContent:"center", alignItems: "center"}}> 
                <ReactLoading type={"spin"} color="#000000" />
            </div><div style={{display: "flex", justifyContent:"center", alignItems: "center"}}> 
                Loading ...
            </div></> }
        </React.Fragment>
    )
}

export default HomeOne;



